<template>
    <div class="h-screen w-full overflow-hidden" :class="{ 'background-default': $route.path !== '/' }">
        <slot :key="slotKey" />
    </div>
</template>

<script>
import { useLiveStore } from '../../stores/liveStore';

export default {
    name: 'TemplateDefault',

    props: {
        slotKey: {
            type: [String, Number],
            default: 'default'
        }
    },

    methods: {

        hexToRgb(hex) {
            // Verifica se 'hex' é uma string válida
            if (!hex || typeof hex !== 'string') {
                console.error('Valor inválido para hex:', hex);
                return null;
            }

            // Remove o caractere '#' se estiver presente
            hex = hex.replace('#', '');

            // Verifica se o formato é válido (3 ou 6 caracteres hexadecimais)
            if (hex.length !== 3 && hex.length !== 6) {
                console.error('Formato inválido para hex:', hex);
                return null;
            }

            // Se for um formato curto (#fff), expande para o formato completo (#ffffff)
            if (hex.length === 3) {
                hex = hex.split('').map(char => char + char).join('');
            }

            // Executa a conversão de hexadecimal para RGB
            const rgb = hex.match(/[A-Za-z0-9]{2}/g).map(v => parseInt(v, 16));

            return rgb; // Retorna um array [r, g, b]
        }

    },

    async mounted() {
        const liveStore = useLiveStore();
        const query = this.$route.query;

        if (!query || !query.plugin) {
            this.$router.push({ name: 'error' });
        }

        let session = JSON.parse(localStorage.getItem(query.plugin));

        // Se não existir uma session não faz nada
        if (!session) {
            session = await this.$functions.saveSession(query);
        }


        // -----------------------------------------------------------
        // Thema do chat
        // -----------------------------------------------------------
        const top_gradient_from = this.hexToRgb(session?.attributes?.top_gradient_from) || [0, 0, 0];
        const top_gradient_to = this.hexToRgb(session?.attributes?.top_gradient_to) || [255, 255, 255];
        const top_text_color = this.hexToRgb(session?.attributes?.top_text_color) || [0, 0, 0];


        const style = document.createElement('style');
        style.innerHTML = `
            .topGradient {
                background: linear-gradient(60deg, rgb(${top_gradient_from.join(',')}) 0%, rgb(${top_gradient_to.join(',')}) 100%);
            }
            .topTextColor {
                color: rgb(${top_text_color.join(',')});
            }
        `;


        const logo_style = session?.attributes?.logo_style
        if (logo_style == 'round') {
            style.innerHTML += `
                .logo {
                    border-radius: 50%;
                    width: 60px;
                    height: 60px;
                }
            `
        } else {
            style.innerHTML += `
                .logo {
                    width: 120px;
                    height: 60px;
                }
            `
        }



        document.head.appendChild(style);
        // -----------------------------------------------------------


        // -----------------------------------------------------------
        // Conexão socket / Caso seja para conectar
        // -----------------------------------------------------------
        if (session?.connected) {

            await liveStore.restoreState(); // Restaura todos os chats do localstorage

            await liveStore.connectSocket({
                external_id: session.external_id,
                settings_workspaces_id: session?.attributes.configs.settings_workspaces_id,
                root_owners_id: session?.attributes?.configs.root_owners_id,
                input_method: session?.attributes.configs.input_method,
            });


        }

        await liveStore.setConfigs(session?.attributes?.configs || []);
        await liveStore.setQuery(session?.query || {});
        await liveStore.setExternalId(session?.external_id);
    },
}
</script>
