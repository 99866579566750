<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <Listbox as="div" v-model="selected" @click="update" class="" :disabled="disabled">
    <ListboxLabel class="block text-xss text-gray-700">{{label}} {{  }}</ListboxLabel>
    <div class="mt-1 relative">
      <ListboxButton class="relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 text-xss" :class="disabled ? 'bg-[#EEE]' : 'bg-white'">
        <span class="block truncate flex">
            <div v-if="selected.icon" class="pr-2">
              <component :is="selected.icon" class="inline-block h-4 w-4 " :class="selected.colorIcon" aria-hidden="true" />
            </div>
            <div class="pr-2">  
              <span v-if="selected.color" class="inline-flex h-3 w-3 rounded-full opacity-75" :class="selected.color"/>
            </div>
            {{ selected.name }}
        </span>
        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <ArrowsUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </ListboxButton>

      <transition :class="showOnTop ? 'mb-1 bottom-full' : ''" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <ListboxOptions class=" absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-xss ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-xss">
          <ListboxOption as="template" v-for="item in data" :key="item.name" :value="item" >
            <li :class="[item.selected ? 'text-white bg-slate-600' : 'text-gray-900 hover:bg-gray-100', 'cursor-default select-none relative py-2 pl-3 pr-9']">
              <span class="flex" :class="[item.selected ? 'font-semibold' : 'font-normal', 'block truncate']">

                <div v-if="item.icon" class="pr-2">
                  <component :is="item.icon" class="inline-block h-4 w-4 " :class="item.colorIcon" aria-hidden="true" />
                </div>  
                <div class="pr-2">  
                  <span v-if="item.color" class="inline-flex h-3 w-3 rounded-full opacity-75" :class="item.color"></span>
                </div>

                {{ item.name }}
              </span>
              <span v-if="item.selected" class="text-white absolute inset-y-0 right-0 flex items-center pr-4">
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script>
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions, } from "@headlessui/vue";
import { CheckIcon, ArrowsUpDownIcon } from "@heroicons/vue/24/solid";

export default {
    name: "FormFildDropDown",
    props: {
        label: String,
        data: Array,
        value: [String, Object, Boolean, Number],
        disabled: Boolean,
        showOnTop: Boolean,
    },
    data() {
        return {          
          selected: {},  
        };
    },
    methods: {
        
        update(){          

          for(let i = 0; i < this.data.length; i++){
            if(this.data[i].value == this.value){
              this.data[i].selected = true;
            }
            else{
              this.data[i].selected = false;
            }
          }              
            
          
          this.$emit('update:modelValue', this.selected.value);
        },        
    },
    components: {
        Listbox,
        ListboxButton,
        ListboxLabel,
        ListboxOption,
        ListboxOptions,
        CheckIcon,
        ArrowsUpDownIcon,
    },
    created() {

      // Existing value
      if(!this.value){
        this.data[0].selected = true;                
        this.selected = this.data[0];        
      }
      else {
        for(let i = 0; i < this.data.length; i++){
          if(this.data[i].value == this.value){
            this.data[i].selected = true;
            this.selected = this.data[i];
          }
          else{
            this.data[i].selected = false;
          }          
        }    
      }

    },
    watch: {
      value(newValue) {
        for(let i = 0; i < this.data.length; i++){
          if(this.data[i].value == newValue){
            this.data[i].selected = true;
            this.selected = this.data[i];
          }
          else{
            this.data[i].selected = false;
          }          
        }    
      },
    },
    mounted() {

      setTimeout(() => {
        this.update();
      }, 150);

    },
};
</script>