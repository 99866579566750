<template>
  <div class="py-2 pb-10">
    <label for="{{forLabel}}" class="block text-sm font-medium text-gray-700">
      <span v-if="required" class="text-rose-600">*</span> {{label}}
    </label>
    <RecursiveSelect
      :options="options"
      :error="fieldsError[field_id]"
      @update:modelValue="onUpdateModelValue"
    />
  </div>
</template>

<script>
import RecursiveSelect from './RecursiveSelect.vue'

export default {
  name: 'MultDropDown',
  components: { RecursiveSelect },
  props: {
    field_id: [String, Number],
    name: String,
    options: {
      type: Array,
      required: true,
    },
    required: {
      type: Boolean,
      default: true,
    },
    fieldsError: Object,        
    label: String,
  },
  methods: {
    onUpdateModelValue(eventObj) {
      if (!eventObj?.recursive_id) {
        this.arrayOptions = [eventObj];
      } else {
        this.arrayOptions = this.arrayOptions
          .filter((item) => item?.recursive_id != eventObj?.recursive_id)
          .concat(eventObj);
      }

      this.arrayOptions = this.removeUnrelatedRecursiveIds(this.arrayOptions);

      this.$emit('update:modelValue', {
        field_id: this.field_id,
        name: this.name,
        label: this.label,
        options: this.arrayOptions,
      });
    },
    removeUnrelatedRecursiveIds(arr) {
      const relatedRecursiveIds = arr.map((obj) => obj.id);
      return arr.filter((obj) =>
        obj.recursive_id === "" || relatedRecursiveIds.includes(obj.recursive_id)
      );
    },
  },
};
</script>
