<template>
  <div class="py-2">
    <label for="{{forLabel}}" class="block text-xss text-gray-700"><span v-if="required" class="text-rose-600">*</span> {{label}}</label>
    <div v-if="description" class="text-gray-500 text-xs">
      {{description}}
    </div>
    <fieldset class="mt-2 ">
      <div class="space-y-2" >
        <div v-for="item in data" :key="item.name" @click="update(item.value)" class="cursor-pointer relative flex items-center " >
          <div class="relative items-start">
            <div class="absolute top-0 w-8 h-8 rounded-full " @click="update(item.value)"></div>
            <input :id="item.value"  type="radio" :checked="item.value === checked" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 hover:ring hover:ring-indigo-500 hover:ring-offset-2 hover:ring-offset-white" />
          </div>
          <div>
            <label :for="item.value" class="cursor-pointer ml-3 block text-sm font-medium text-gray-700">
              {{ item.name }}
              <div v-if="item.description" class="text-gray-500 text-xs">{{item.description}}</div>
            </label>
          </div>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>

import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'

export default {
  name: 'RadioList',
  props: {
    description: String,
    data: Array,    
    value: String,
    field_id: {
        type: [String, Number],
        required: false,
    },
    field_type: String,
    label: {
      type: String,
      required: false,
    },    
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    RadioGroup,
    RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption,
  },
  data() {
    return {
       checked: '',
    }    
  },
  methods: {
    update(value){
      this.checked = value;

      if(this.field_id){
        this.$emit('update:modelValue', {value: value, field_id: this.field_id, label: this.label, field_type: this.field_type});
      
      } else {
        this.$emit('update:modelValue', value);
      }

    }
  },
  created() {

    // aguarda 500ms para que o componente pai possa setar o valor
    // caso contrário, o valor não é setado
    this.$Utils.sleep(500).then(() => {
      if(this.value){
        this.update(this.value);      
      }
      else {
        this.update(this.data[0].value);
      }
    })


  },
}
</script>