export default class Utils {

    static generateUUID() {
        let uuid = '';
        const randomValues = new Uint8Array(16);
        crypto.getRandomValues(randomValues);

        randomValues[6] &= 0x0f;
        randomValues[6] |= 0x40;

        randomValues[8] &= 0x3f;
        randomValues[8] |= 0x80;

        for (let i = 0; i < 16; i++) {
            uuid += randomValues[i].toString(16).padStart(2, '0');
            if ([3, 5, 7, 9].includes(i)) {
            uuid += '-';
            }
        }
        return uuid;
    };

    static limitChars(value, maxLength = 200) {
        if (value.length > maxLength) {
            return value.substring(0, maxLength) + '...';
        } else {
            return value;
        }
    };

    static formConstructorValidation(attributs, fields){
        
        let errors = {};

        // Poderá ser necessário adicionar uma validação de OBJ (no caso de condicional)

        for(let i = 0; i < fields.length; i++){
            if(fields[i].required && !attributs[fields[i].name] || !attributs[fields[i].name]?.value ){

                if(!fields[i].required){ continue;}

                if(fields[i].fieldType == 'Phone'){
                    if(attributs[fields[i].name]?.phones?.length > 0){
                        for(let j = 0; j < attributs[fields[i].name].phones.length; j++){
                            if(attributs[fields[i].name].phones[j].number){
                                continue;
                            }
                            errors[fields[i].name] = 'Campo obrigatório';
                        }
                        continue;
                    }
                }
                if(fields[i].fieldType == 'Address'){
                    if(attributs[fields[i].name]?.fields?.zipcode && attributs[fields[i].name]?.fields?.number && attributs[fields[i].name]?.fields?.locality && attributs[fields[i].name]?.fields?.neighborhood && attributs[fields[i].name]?.fields?.publicplace){
                        continue;
                    }
                }

                if(fields[i].fieldType == 'Upload'){
                    continue;
                }

                if(fields[i].fieldType == 'MultSelect'){                
                    if(attributs[fields[i].name]?.options?.length > 0){                        
                        continue;
                    }
                }

                errors[fields[i].name] = 'Campo obrigatório';                    
            }
        }
        return errors;
    };

    static sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));    
    }


    static mysqlDate(date) {
        const now = moment();
        const receivedDate = moment(date);
    
        //const diffInMinutes = now.diff(receivedDate, 'minutes');
        const diffInHours = now.diff(receivedDate, 'hours');
        const diffInDays = now.diff(receivedDate, 'days');
        const diffInMonths = now.diff(receivedDate, 'months');
        const diffInYears = now.diff(receivedDate, 'years');
    
        if (diffInHours < 24) {
            return receivedDate.format('HH:mm');  // Aqui está a mudança
        } else if (diffInDays < 31) {
            return `há ${diffInDays} dia${diffInDays > 1 ? 's' : ''}`;
        } else if (diffInMonths < 12) {
            return `há ${diffInMonths} mês${diffInMonths > 1 ? 'es' : ''}`;
        } else if (diffInYears < 1) {
            return `há ${diffInYears} ano${diffInYears > 1 ? 's' : ''}`;
        } else {
            return receivedDate.format('DD/MM/YYYY');
        }
    }

    static dateUSToBR(dataEntrada){
        let data;
        if (dataEntrada.includes('T')) {
          data = new Date(dataEntrada);
        } else {
          const [dataParte, horaParte] = dataEntrada.split(' ');
          const [ano, mes, dia] = dataParte.split('-');
          const [horas, minutos, segundos] = horaParte.split(':');
          data = new Date(ano, mes - 1, dia, horas, minutos, segundos);
        }
  
        const dia = String(data.getDate()).padStart(2, '0');
        const mes = String(data.getMonth() + 1).padStart(2, '0');
        const ano = data.getFullYear();
        const horas = String(data.getHours()).padStart(2, '0');
        const minutos = String(data.getMinutes()).padStart(2, '0');
        const segundos = String(data.getSeconds()).padStart(2, '0');
  
        return `${dia}/${mes}/${ano} ${horas}:${minutos}:${segundos}`;
    }
    

    static basicValidation(attributs, fields){
        let errors = {};
        for(let i = 0; i < fields.length; i++){
            if(fields[i].required && !attributs[fields[i].name]){
                errors[fields[i].name] = 'Campo obrigatório';
            }
        }
        return errors;
    };
   
}