<template>
  <div class="relative">
    <!-- Botão para abrir o Emoji Picker -->
    <button @click="togglePicker" class="flex items-center justify-center h-10 text-gray-400 hover:text-gray-900">
      <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
      </svg>
    </button>
    <!-- Emoji Picker Panel -->
    <transition name="fade">
      <div v-if="isPickerOpen" class="emoji-panel bg-white absolute max-h-96 w-80 flex flex-col rounded-lg shadow-xl border border-gray-100" @click.stop>
        <div class="emoji-container flex flex-wrap overflow-y-auto p-2">
          <div v-for="emoji in emojis" :key="emoji" class="emoji-item cursor-pointer text-xl m-1" @click="selectEmoji(emoji)">
            {{ emoji }}
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'EmojiPickerButton',
  data() {
    return {
      isPickerOpen: false,
      emojis: ['😀', '😃', '😄', '😁', '😆', '😅', '🤣', '😂', '🙂', '🙃', '😉', '😊', '😇', '🥰', '😍', '🤩', '😘', '😗', '😚', '😙', '😋', '😛', '😜', '🤪', '😝', '🤑', '🤗', '🤭', '🤫', '🤔', '🤐', '🤨', '😐', '😑', '😶', '😏', '😒', '🙄', '😬', '🤥','👎','👍','👋','🤜','🤛','🤝','🙏'],
    };
  },
  methods: {
    togglePicker() {
      this.isPickerOpen = !this.isPickerOpen;
    },
    selectEmoji(emoji) {
      this.$emit('select', emoji);
      this.isPickerOpen = false;
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target) && this.isPickerOpen) {
        this.isPickerOpen = false;
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  }
}
</script>

<style scoped>
.emoji-panel {
  right: 0;
  bottom: calc(100% + 1.5rem);
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.emoji-container {
  max-height: 256px;
}
.emoji-item {
  padding: 4px;
}
</style>
