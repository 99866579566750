<template>
    <div class="pt-2">
      <select v-model="selected" class="defaultInput w-full" :class="{ 'bg-rose-50': error }">
        <option disabled value="null">Selecione uma opção</option>
        <option v-for="option in options" :key="option.id" :value="option.id">
          {{ option.name }}
        </option>
      </select>
      <RecursiveSelect
        v-if="selectedOption?.link?.length"
        :options="selectedOption.link"
        :parentId="selected"
        :error="error"
        @update:modelValue="$emit('update:modelValue', $event)"
      />
    </div>
  </template>
  
  <script>
  export default {
    name: 'RecursiveSelect',
    props: {
      options: {
        type: Array,
        required: true,
      },
      parentId: {
        type: String,
        default: null,
      },
      error: String,
    },
    data() {
      return {
        selected: null,
      };
    },
    computed: {
      selectedOption() {
        return this.options.find((option) => option.id === this.selected);
      },
    },
    watch: {
      selected() {
        if (this.selectedOption) {
          this.$emit('update:modelValue', {
            id: this.selectedOption.id,
            name: this.selectedOption.name,
            value: 'name',
            recursive_id: this.parentId || '',
          });
        }
      },
    },
  };
  </script>
  