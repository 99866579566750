// liveStore.js
import { defineStore } from 'pinia'
import { connect, disconnect } from '../resources/functions/Socket';


export const useLiveStore = defineStore('liveStore', {
  state: () => ({
    configs: {},
    query: {},
    external_id: '',
    socket: null,
    chats: {}, // Lista de chats ativos
    eventsRegistered: false,
  }),
  actions: {
    setConfigs(newSession) {
      this.configs = newSession;
    },
    setQuery(newQuery) {
      this.query = newQuery;
    },
    setExternalId(newExternalId) {
      this.external_id = newExternalId;
    },
    async connectSocket(userData) {
      this.socket = await connect(userData);

      if (!this.eventsRegistered) {
        this.registerSocketEvents();
      }

    },
    disconnectSocket() {
      disconnect();
      this.eventsRegistered = false; // Permite que os eventos sejam registrados novamente
    },

    // ----------------------------------------------------------------------------------------
    // Funções do chat 
    // ----------------------------------------------------------------------------------------


    async sendMessage(objMessage) {


      this.socket.emit('chat:send:message', { objMessage });


    },


    async startLiveChatDepartament(userData, formData) {

      return new Promise(async (resolve, reject) => {
        try {
          if (!this.socket) {
            this.socket = await connect(userData);

            let session = JSON.parse(localStorage.getItem(this.query.plugin)) || {};
            session.connected = true;
            localStorage.setItem(this.query.plugin, JSON.stringify(session));
          }

          // -------------------------------------------------------
          // Emitir evento para criar o chat
          // -------------------------------------------------------
          this.socket.emit("chat:departament:start", formData, (response) => {

            if (response.error) {
              reject(response.error);
            } else {

              // -----------------------------------------------------------------
              // Adiciona o chat ao localstorage na sessão do plugin
              // -----------------------------------------------------------------
              let session = JSON.parse(localStorage.getItem(this.query.plugin)) || {};
              session.chats.push(response.ticket);
              localStorage.setItem(this.query.plugin, JSON.stringify(session));

              // -----------------------------------------------------------------
              // Adiciona o chat ao array de chats
              // -----------------------------------------------------------------
              this.chats[response.ticket.ticket_id] = response.ticket;

              resolve(response);
            }
          });
        } catch (error) {
          console.error('Erro ao conectar ou iniciar chat:', error);
          reject(error);
        }
      });
    },

    restoreState() {
      let interval = setInterval(() => {
        const session = JSON.parse(localStorage.getItem(this.query.plugin)) || {};
        if (session && session.connected) {
          clearInterval(interval);
          for (let i = 0; i < session.chats.length; i++) {
            this.chats[session.chats[i].ticket_id] = session.chats[i];
          }
        }
      }, 200);
    },

    // ----------------------------------------------------------------------------------------
    // Eventos do socket
    // ----------------------------------------------------------------------------------------

    registerSocketEvents() {
      if (!this.socket) return;

      // Lista de eventos conhecidos e suas funções de callback
      const socketEvents = {

        'chat:upload:file:response': (data) => {

          console.log(data)


        },

        'chat:restore': (data) => {
          let session = JSON.parse(localStorage.getItem(this.query.plugin)) || {};
          session.connected = true;
          session.chats = data;
          localStorage.setItem(this.query.plugin, JSON.stringify(session));
          data.forEach(ticket => {
            this.chats[ticket.ticket_id] = ticket;
          });
        },

        'chat:queue_position': (data) => {
          try {
            this.chats[data.ticket_id].queue_position = data.queue_position;
            let session = JSON.parse(localStorage.getItem(this.query.plugin)) || {};
            session.chats.forEach(chat => {
              if (chat.ticket_id === data.ticket_id) {
                chat.queue_position = data.queue_position;
              }
            });
            localStorage.setItem(this.query.plugin, JSON.stringify(session));
          } catch (e) {
            console.log(e);
          }
        },

        'chat:queue_position:update': (data) => {
          Object.values(this.chats).forEach(chat => {
            if (chat.status === 'queue' && chat.settings_departaments_id === data.settings_departaments_id && chat.queue_position > data.queue_position) {
              chat.queue_position--;
            }
          });

          // Atualizar o localstorage
          let session = JSON.parse(localStorage.getItem(this.query.plugin)) || {};
          if (session.chats) {
            Object.values(session.chats).forEach(chat => {
              if (chat.status === 'queue' && chat.settings_departaments_id === data.settings_departaments_id && chat.queue_position > data.queue_position) {
                chat.queue_position--;
              }
            });
          }

          localStorage.setItem(this.query.plugin, JSON.stringify(session));
        },


        'chat:status': (data) => {
          try {
            this.chats[data.ticket_id].status = data.status;
            let session = JSON.parse(localStorage.getItem(this.query.plugin)) || {};
            session.chats.forEach(chat => {
              if (chat.ticket_id === data.ticket_id) {
                chat.status = data.status;
              }
            });
            localStorage.setItem(this.query.plugin, JSON.stringify(session));
          } catch (e) {
            console.log(e);
          }
        },

        'chat:network:sendMessage:response': (data) => {

          // Caso seja uma mensagem de fechamento de chat
          if(data.message.status === 'closed') {
            this.chats[data.ticket_id].status = 'closed';
          }

          this.chats[data.ticket_id].messages.push(data.message);

          // Salva a mensagem no localstorage
          let session = JSON.parse(localStorage.getItem(this.query.plugin)) || {};
          session.chats.forEach(chat => {
            if (chat.ticket_id === data.ticket_id) {

              // Se a mensagem for de fechamento, atualiza o status do chat
              if(data.message.status === 'closed') {
                chat.status = 'closed';
              }
              chat.messages.push(data.message);
            };

          });

          localStorage.setItem(this.query.plugin, JSON.stringify(session));


        },

        'chat:network:close:response': (data) => {


        },

        'chat:notification': (data) => {


          // Adiciona a notificação ao chat
          this.chats[data.ticket_id].messages.push(data);

          // Salva a mensagem no localstorage
          
          let session = JSON.parse(localStorage.getItem(this.query.plugin)) || {};
          session.chats.forEach(chat => {
            if (chat.ticket_id === data.ticket_id) {
              chat.messages.push(data);
            }
          });

          localStorage.setItem(this.query.plugin, JSON.stringify(session));
          

        },

        'chat:disconnect': (data) => {
          let session = JSON.parse(localStorage.getItem(this.query.plugin)) || {};
          session.connected = false;
          session.chats = [];
          localStorage.setItem(this.query.plugin, JSON.stringify(session));
          disconnect();
        },

        'error': (error) => {
          console.error('Socket error:', error);
        }
      };


      Object.keys(socketEvents).forEach(event => {
        this.socket.on(event, socketEvents[event]);
      });

      // Detectar e lidar com eventos desconhecidos
      this.socket.onAny((event, ...args) => {
        if (!socketEvents.hasOwnProperty(event)) {
          console.log(`Não implementado: ${event}`);
          console.log(args);
        }
      });


      this.eventsRegistered = true; // Evita registrar o evento mais de uma vez

    }
  }


});