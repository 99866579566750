<template>
    <div class="relative">
        <div v-if="status" class="absolute h-2.5 w-2.5 rounded-full top-0 -left-1" :class="status && status === 'online' ? 'bg-green-700' : 'bg-rose-700'"></div>
        <div v-if="size=='min'">
            <img class="h-4 w-4 rounded-full border shadow-sm" :src="avatar" alt="" @error="handleError" />
        </div>        
        <div v-if="size=='xs'">
            <img class="h-6 w-6 rounded-full border shadow-sm" :src="avatar" alt="" @error="handleError" />
        </div>
        <div v-if="size=='sm'">
            <img class="h-8 w-8 rounded-full border shadow-sm" :src="avatar" alt="" @error="handleError" />
        </div>
        <div v-if="size=='md'">
            <img class="h-10 w-10 rounded-full border shadow-sm" :src="avatar" alt="" @error="handleError" />
        </div>
        <div v-if="size=='lg'">
            <img class="h-12 w-12 rounded-full border shadow-sm" :src="avatar" alt="" @error="handleError" />
        </div>           
    </div>
</template>

<script>
export default {
    name: "Avatar",
    props: {
        avatar: {
            type: String,
            default: '/img/avatar.jpg',
        },
        size: {
            type: String,
            default: 'xs',
        },
        status: {
            type: String,
            default: '',
        },
    },
    methods: {
        handleError(event) {
            event.target.src = '/img/avatar.jpg'; // Define a imagem padrão caso ocorra um erro ao carregar a imagem especificada
        },
    },
}
</script>
