<template>
    <div class="py-2">
      <label for="{{forLabel}}" class="block text-sm font-medium text-gray-700"><span v-if="required" class="text-rose-600">*</span> {{label}}</label>
      <select v-model="contitional" class="defaultInput w-full">
        <option value="">Selecione</option>
        <option v-for="option in options" :value="option">
          {{ option.name }}
        </option>
      </select>
      <div v-if="contitional && contitional.fields">
        <FormConstructor :form="contitional.fields" :fieldsError="fieldsError" :formErrors="formErrors" :attributs="formData.attributs" />
      </div>
    </div>
  </template>
  
  
  <script>
  
  export default {
    name: 'SelectConditional',
    
    props: {
      label: {
        type: String,
        required: false
      },
      name: {
        type: String,
        required: true
      },
      label: {
        type: String,
        required: false
      },
      required: {
        type: Boolean,
        required: false
      },
      value: {
        type: Object,
        required: false
      },
      options: {
        type: Array,
        required: false
      }
    },
    data() {
      return {
        contitional: null,
        fieldsError: {},
        formErrors: {},
        formData: {
            attributs: {},
        },

      }
    },
    watch: {

      formData: {
        handler: function (val) {
          
            this.$emit('update:modelValue', val.attributs);

        },
        deep: true
      }

    },

    created() {
      //this.formData.attributs.value = this.value
    },

    components: {
    },
  }
  </script>
  