<template>

    <div v-for="field in form" :key="field.id">
    
        <div v-if="!field.hidden">

            <template v-if="field.fieldType == 'InputText'">               
                <InputText :label="field.label" :required="field.required"  :name="field.name" :fieldsError="fieldsError" :field_id="field.id"  :value="attributs[field.name]?.value" :error="formErrors[field.name]" :placeholder="field.placeholder" v-model="attributs[field.name]"  />
            </template>

            <template v-if="field.fieldType == 'TextArea'">        
                <Textarea :label="field.label" :required="field.required"   :name="field.name" :fieldsError="fieldsError" :field_id="field.id" :value="attributs[field.name]?.value" :error="formErrors[field.name]" v-model="attributs[field.name]"  />
            </template>

            <template v-if="field.fieldType == 'Select'">
                <DropDownSearch :label="field.label" :required="field.required" :name="field.name" :fieldsError="fieldsError" :field_id="field.id" :field_type="field.fieldType" :value="attributs[field.name]?.value"  :dataRows="field.options" valueType="name"  v-model="attributs[field.name]" />                    
            </template>

            <template v-if="field.fieldType == 'MultSelect'">                
                <MultDropDown :label="field.label" :required="field.required"  :name="field.name" :fieldsError="fieldsError" :field_id="field.id" :field_type="field.fieldType" :options="field.options" valueType="name"  v-model="attributs[field.name]" />
            </template>

            <template v-if="field.fieldType == 'Conditional'">
                <SelectConditional :label="field.label"  :required="field.required" :name="field.name" :fieldsError="fieldsError" :field_id="field.id" :options="field.options" valueType="name"  v-model="attributs[field.name]" />
            </template>            

            <template v-if="field.fieldType == 'Options'">
                <Radio :label="field.label" :required="field.required"  :name="field.name" :fieldsError="fieldsError" :field_id="field.id" :field_type="field.fieldType" :value="attributs[field.name]?.value" :data="field.options"  v-model="attributs[field.name]"  />
            </template>

            <template v-if="field.fieldType == 'Checkbox'">                
                <Checkbox :label="field.label" :required="field.required"  :name="field.name" :fieldsError="fieldsError"  :field_id="field.id" :field_type="field.fieldType" :value="attributs[field.name]?.value" :options="field.options"  v-model="attributs[field.name]"  />
            </template>            

            <template v-if="field.fieldType == 'HtmlCode'">
                <div v-html="field.html"></div>
            </template>

            <!--
            <template v-if="field.fieldType == 'Upload'">
                <Upload :label="field.label"  :name="attributs[field.name]" :fieldsError="fieldsError"  v-model="attributs[field.name]" />
            </template>         

            <template v-if="field.fieldType == 'Products'">
                <Products :field_id="field.id" :required="field.required"  :label="field.label" :fieldsError="fieldsError" :settings_workspaces_id="field.settings_workspaces_id" :root_owners_id="field.root_owners_id" :value="attributs[field.name]" v-model="attributs[field.name]" :fields="field.fields" />
            </template>             

            <template v-if="field.fieldType == 'Address'">
                <Address :field_id="field.id" :required="field.required"  :label="field.label" :name="field.name" :fieldsError="fieldsError" :value="attributs[field.name]" v-model="attributs[field.name]" :fields="field.fields" />
            </template> 

            <template v-if="field.fieldType == 'Phone'">
                <Phones :field_id="field.id" :required="field.required"  :label="field.label" :name="field.name" :fieldsError="fieldsError" :value="attributs[field.name]?.phones" v-model="attributs[field.name]" :types="field.types" />
            </template>         
            
            <template v-if="field.fieldType == 'Coordinates'">
                <Coordinates :label="field.label" :label_x="field.label_x" :label_y="field.label_y" :info="field.info" :placeholder="field.placeholder" :required="field.required"  :name="field.name" :fieldsError="fieldsError" :field_id="field.id" :value="attributs[field.name]?.value"   v-model="attributs[field.name]" />
            </template>
            -->

        </div>
    
    </div>

</template>

<script>

//import Products from '../cruds/form/inteligentsFields/Products.vue'
//import Address from '../cruds/form/inteligentsFields/Address.vue'
//import Phones from '../cruds/form/inteligentsFields/Phones.vue'
//import Coordinates from '../cruds/form/inteligentsFields/Coordinates.vue'


export default {

    name: 'FormConstructor',

    props: {
        form: {
            type: Array,
            required: false
        },
        formErrors: {
            type: Object,
            required: false
        },
        attributs: {
            type: Object,
            required: true
        },        

        fieldsError: {
            type: Object,
            required: true
        }
    },

    
    components: {
        //Products,
        //Address,
        //Phones,
        //Coordinates,
    },


}

</script>