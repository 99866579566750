<template>
  <Listbox as="div" v-model="selectedDepartmentId" class="w-full" :disabled="departments.length === 0">
    <label class="block text-xss text-gray-700"><span v-if="required" class="text-rose-600">*</span> {{ label }}</label>
    <div class="mt-1 relative">
      <ListboxButton :class="[
    'relative w-full py-2 pl-3 pr-10 text-left border rounded-md shadow-sm cursor-default focus:outline-none',
    'border-gray-300',
    selectedDepartmentId && hasChildren(selectedDepartmentId) ? 'bg-red-100 focus:ring-red-500 focus:border-red-500' : 'bg-white focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
  ]">
        <span class="block truncate text-sm">
          {{ getDepartmentName(selectedDepartmentId) }}
        </span>
        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <ArrowsUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </ListboxButton>
      <ListboxOptions
        class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-sm ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none">
        <ListboxOption as="template" v-for="department in organizedDepartments" :key="department.id"
          :value="department.id" :disabled="hasChildren(department.id)">
          <li :class="[
    department.id === selectedDepartmentId ? 'text-white bg-indigo-600' : 'text-gray-900',
    'cursor-default select-none relative py-2 pl-3 pr-9',
    hasChildren(department.id) ? 'opacity-50 cursor-not-allowed bg-gray-100' : 'hover:bg-indigo-600 hover:text-white'
  ]">
            <div class="flex items-center">
              <span class="ml-3 block truncate"
                :class="{ 'font-semibold': department.id === selectedDepartmentId, 'font-normal': department.id !== selectedDepartmentId }">
                {{ generateLabel(department) }}
              </span>
            </div>
            <span v-if="department.id === selectedDepartmentId"
              class="text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4">
              <CheckIcon class="h-5 w-5" aria-hidden="true" />
            </span>
          </li>
        </ListboxOption>
      </ListboxOptions>
    </div>
  </Listbox>
</template>

<script>
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from "@headlessui/vue";
import { CheckIcon, ArrowsUpDownIcon } from "@heroicons/vue/24/solid";

export default {
  name: 'SelectDepartament',
  components: {
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    CheckIcon,
    ArrowsUpDownIcon
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    departments: {
      type: Array,
      default: () => []  // Define um valor padrão como array vazio para evitar erros
    },
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedDepartmentId: this.modelValue
    };
  },
  computed: {
    organizedDepartments() {
      if (!this.departments || this.departments.length === 0) {
        console.log('No valid departments provided.');
        return [];
      }

      const map = new Map();
      this.departments.forEach(dept => {
        map.set(dept.id, { ...dept, children: [] });
      });

      const result = [];
      map.forEach(dept => {
        if (dept.recursive_id && map.has(dept.recursive_id)) {
          const parent = map.get(dept.recursive_id);
          parent.children.push(dept);
        } else {
          result.push({ ...dept, nivel: 0 });
        }
      });

      return this.flattenDepartments(result);
    }
  },
  methods: {
    updateSelection(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('update:modelValue', newValue);
      }
    },
    getDepartmentName(id) {
      const department = this.departments.find(dept => dept.id === id);
      return department ? department.name : 'Selecione um departamento';
    },
    generateLabel(department) {
      return `${' '.repeat(department.nivel * 4)} ${department.name}`;
    },
    hasChildren(id) {
      return this.departments.some(department => department.recursive_id === id);
    },
    flattenDepartments(departments) {
      return departments.reduce((acc, dept) => {
        return acc.concat(dept, this.flattenDepartments(dept.children || []));
      }, []);
    }
  },
  watch: {
    modelValue(newValue) {
      this.selectedDepartmentId = newValue;
    },
    selectedDepartmentId(newValue) {
      this.updateSelection(newValue, this.modelValue);
    }
  }
}
</script>
