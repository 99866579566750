import axios from 'axios'; 


//class, status, rota, query, params

export function changeIframeClass(className, statusWidget,  routeName, query, params = {}) {
  window.parent.postMessage({
    type: 'updateWidget',
    iframeName: 'tecliaWindow',
    routeName: routeName,
    query: query,
    params: params,
    className: className,
    statusWidget: statusWidget
  }, '*'); // Use the exact host URL in production for security
}


export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0,
      v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export async function saveSession(query) {

  const timeStamp = new Date().getTime();
  let session = JSON.parse(localStorage.getItem(query.plugin)) || {};
 
  if (!session.attributes || session.attributes.timestamp < timeStamp - 300000) {
    try {
      const attributes = await axios.get(`${process.env.VUE_APP_PLUGIN_URL}/plugins/${query.plugin}.json?${timeStamp}`);
      session.attributes = attributes.data;
      session.attributes.timestamp = timeStamp
    } catch (error) {      
      // Caso as configurações não sejam encontradas, redireciona para a página de error
      //window.location.href = '/error';
      return 
    }
  }

  session.chats = session.chats || [];

  // Cria ou atualiza a session
  session.external_id = session.external_id || this.uuidv4();
  session.query = query;

  // Connected
  session.connected = session.connected || false;

  // Salva a session no localstorage
  localStorage.setItem(query.plugin, JSON.stringify(session));

  return session;
}

export async function getSession(plugin) {
  let session = JSON.parse(localStorage.getItem(plugin)) || {};
  return session;
}

export async function minimizeWidget(vueInstance) {
  vueInstance.$functions.changeIframeClass('widget', vueInstance.$route.query, 'widget', 'close');
  vueInstance.$router.push({ name: 'widget', query: vueInstance.$route.query });
}