import { createRouter, createWebHistory } from 'vue-router'
import Widget from '../views/Widget.vue'

const routes = [
  { path: '/', name: 'widget', component: Widget },
  { path: '/home', name: 'home', component: () => import('../views/Home.vue')},
  { path: '/live', name: 'live', component: () => import('../views/Live/Live.vue')},
  { path: '/live/:id', name: 'livechat', component: () => import('../views/Live/LiveChat.vue')},
  { path: '/live/continue', name: 'live:continue', component: () => import('../views/Live/LiveContinue.vue')},
  { path: '/live/continue/:id', name: 'livechat:continue:id', component: () => import('../views/Live/LiveChat.vue')}, // Facilita a organização
  

  { path: '/error', name: 'error', component: () => import('../views/Error.vue')}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
