<template>
<div class="py-2">  
    <div class="flex gap-2" >
      <label for="{{forLabel}}" class="block text-sm font-medium text-gray-700"><span v-if="required" class="text-rose-600">*</span> {{label}}</label>
    </div>
    <div class="relative rounded-md p-2" :class="name ? fieldsError[field_id] ? 'bg-rose-50' : '': ''" >
        <div v-for="option in options"  :key="option.name" class="">
          <div class="flex gap-2 items-center ">
              <div>
                <div @click="select(option.name)" class="absolute h-5 w-5 cursor-pointer	"></div>
                <div>
                    <input type="checkbox" class="border-gray-300 appearance-none checked:bg-indigo-600 cursor-pointer" @click="select(option.name)" :checked="verifyCheck(option.name)" :id="option.name" :name="option.name" :value="option.name">
                </div>
              </div>
              <div class="text-sm cursor-pointer" @click="select(option.name)">
                  {{option.name}}
              </div>
          </div>
      </div>
    </div>
    
  </div>
</template>

<script>

export default {
  name: 'Checkbox',
  
  props: {
    name: {
        type: [String, Number],
        required: false,
    },
    options: {
      type: Array,
      default: [],
    },
    required: {
      type: Boolean,
      default: false,
    },
    field_id: {
        type: [String, Number],
        required: false,
    },
    field_type: String,
    label: {
      type: String,
      required: false,
    },
    fieldsError: {
      type: Object,
      required: false,
    },    
    value: {
      type: [String, Array, Object, Number, Boolean, Function],
      required: false
    },
    modelValue: {

      type: [String, Array, Object, Number, Boolean, Function],
      required: false
    }
  },

  data() {
    return {
      options_check: [],
    }
  },

  methods: {
    select(option){
      if(this.options_check.indexOf(option) == -1){
        this.options_check.push(option);
      }else{
        this.options_check.splice(this.options_check.indexOf(option), 1);
      }

      if(this.field_id){
        this.$emit('update:modelValue', {value: this.options_check, field_id: this.field_id, label: this.label, field_type: this.field_type});
      
      } else {
        this.$emit('update:modelValue', this.options_check);
      }
    },

    verifyCheck(option){
      if(this.options_check.indexOf(option) != -1){
        return true;
      }else{
        return false;
      }
    },
  },

  created(){
    if(this.value){
      this.options_check = this.value;
    }
  },

}
</script>

