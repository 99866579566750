import io from 'socket.io-client';

let socket = null;

const initializeSocket = () => {
    if (!socket) {
        socket = io(process.env.VUE_APP_SOCKET_URL + "/public", {
            reconnection: true,
            autoConnect: false, 
            transports: ['websocket'],
        });
    }
    console.log('Socket inicializado'); // Melhorado a clareza da mensagem de log
    return socket;
}

const connect = async (userData) => {
    if (!socket) {
        initializeSocket(); 
    }
    if (!socket.connected) {
        console.log('Conectando...'); // Ajustado para indicar que está no processo de conexão
        socket.io.opts.query = { userData: JSON.stringify(userData) }; 
        await new Promise((resolve, reject) => {
            socket.connect();
            socket.once('connect', resolve); 
            socket.once('connect_error', reject); // Adicionado tratamento de erro de conexão
            socket.once('connect_timeout', reject); // Adicionado tratamento para timeout
        });
        console.log('Conectado'); // Confirmar a conexão após a promessa ser resolvida
    }
    return socket;
}

const disconnect = () => {
    if (socket && socket.connected) {
        console.log('Desconectando...'); // Mensagem de log para desconexão
        socket.disconnect();
        console.log('Desconectado'); // Confirmar a desconexão
    }
}

export { initializeSocket, connect, disconnect, socket };