import * as Functions from "./resources/functions/Functions.js";
import './assets/styles.css';
//import './registerServiceWorker';


import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './views/Index.vue';
import router from './router';
import { socket } from './resources/functions/Socket';

import Editor from './resources/components/cruds/Editor.vue';
import ChatUploadInput from './resources/components/cruds/ChatUploadInput.vue';
import EmojiPicker from './resources/components/cruds/EmojiPicker.vue';
import UploadFileList from './resources/components/cruds/UploadFileList.vue';


import FormUtils from "./logic/functions/FormUtils.js";
import Utils from './logic/functions/Utils.js';
import DepartamentsFunctions from './logic/functions/Departaments.js';

import FormConstructor from "./resources/components/cruds/form/FormConstructor.vue";

import SelectDepartament from './resources/components/cruds/form/fields/SelectDepartament.vue';
import Avatar from './resources/components/Avatar.vue';
import DropDown from './resources/components/cruds/form/fields/DropDown.vue';
import DropDownSearch from './resources/components/cruds/form/fields/DropDownSearch.vue';
import InputText from './resources/components/cruds/form/fields/InputText.vue';
import Textarea from './resources/components/cruds/form/fields/Textarea.vue';
import MultDropDown from './resources/components/cruds/form/fields/MultDropDown.vue';
import SelectConditional from './resources/components/cruds/form/fields/SelectConditional.vue';
import Radio from './resources/components/cruds/form/fields/Radio.vue';
import Checkbox from './resources/components/cruds/form/fields/Checkbox.vue';
//import Upload from './resources/components/cruds/form/fields/Upload.vue';

const app = createApp(App);
const pinia = createPinia();

app.use(pinia);
app.use(router);

app.mount('#app');

pinia.use(() => ({
    router: router,
}));

// Componentes globais
app.component('Avatar', Avatar)
app.component('Editor', Editor)
app.component('ChatUploadInput', ChatUploadInput)
app.component('EmojiPicker', EmojiPicker)
app.component('UploadFileList', UploadFileList)

//app.component('editor', Editor);

// Componentes de formulário
app.component('FormConstructor', FormConstructor)
app.component('DropDown', DropDown)
app.component('DropDownSearch', DropDownSearch)
app.component('SelectDepartament', SelectDepartament)
app.component('InputText', InputText)
app.component('Textarea', Textarea)
app.component('MultDropDown', MultDropDown)
app.component('SelectConditional', SelectConditional)
app.component('Radio', Radio)
app.component('Checkbox', Checkbox)


app.config.globalProperties.$functions = Functions;
app.config.globalProperties.$socket = socket;
app.config.globalProperties.$Utils = Utils;
app.config.globalProperties.$FormUtils = FormUtils;
app.config.globalProperties.$DepartamentsFunctions = DepartamentsFunctions;




window.addEventListener('popstate', function (event) {
    window.parent.postMessage({ type: 'navigateBack' }, '*');
});