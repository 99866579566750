<template>
  <div class="py-2">
    <label for="{{forLabel}}" class="block text-xss text-gray-700"><span v-if="required" class="text-rose-600">*</span> {{label}}</label>
    <div class="mt-1">
      <textarea :value="value" @input="updateValue" :placeholder="placeholder" :disabled="disabled" :rows="rows" class="defaultInput" :class="name ? fieldsError[field_id] ? 'bg-rose-50' : '': ''" ></textarea>
      <p v-if="error" class="mt-2 text-sm text-rose-600">{{error}}</p>
    </div>
  </div>
</template>

<script>

export default {
    name: 'InputText',
    props: {
        rows: {
            type: Number,
            default: 3,
        },
        required: {
            type: Boolean,
            default: false,
        },
        label: {
          type: String,
          required: false,
        },
        name: {
            type: String,
            required: false,
        },
        field_id: {
            type: [String, Number],
            required: false,
        },    
   
        value: [String, Object, Boolean, Number],
        placeholder: String,
        disabled: Boolean,
        error: String,
        fieldsError: {
          type: Object,
          required: false,
        },         
    },
    methods: {
        updateValue(event) {
          if(this.field_id){
            this.$emit('update:modelValue', {value: event.target.value, field_id: this.field_id, label: this.label});
          
          } else {
            this.$emit('update:modelValue', event.target.value);
          }
        },
        forLabel() {
            return this.label.replace(/\s/g, '').toLowerCase();
        },
    },
    created() {
      if(this.field_id){
        this.$emit('update:modelValue', {value:this.value, field_id: this.field_id, label: this.label});
        
      } else {
        this.$emit('update:modelValue', this.value);
      }

    },
}
</script>