<template>
    <div>
        <span class="block text-sm font-medium text-gray-700 mb-1" v-if="label">
            {{ label }}
        </span>
        <div :class="border ? 'relative p-0.5 border rounded-md' : 'relative'">
            <Editor :id="editorId" :api-key="apiKey" v-model="message" @input="updateValue" :init="conf"/>
            <div class="absolute  right-4 bottom-1  w-[120px] h-[20px] text-xs items-right"> </div>
        </div>
        <p v-if="error" class="mt-2 text-sm text-rose-600">Message is required</p>
    </div>    
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import { getTinymce } from '@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE';

// Função para extrair o ID do vídeo do YouTube
function extractVideoId(url) {
  const videoId = url.split('v=')[1];
  const ampersandPosition = videoId.indexOf('&');
  if (ampersandPosition !== -1) {
    return videoId.substring(0, ampersandPosition);
  }
  return videoId;
}


export default {
    components: {
        Editor,
    },
    props: {
        error: String,
        value: String,
        label: String,
        initialValue: String,
        statusBar: {
            type: Boolean,
            default: true,
        },
        resize: {
            type: Boolean,
            default: true
        },
        height: {
            default: 250
        },
        editorId: String,
        customConfig: {
            type: Object,
            default: () => ({})
        },
        border: {
            type: Boolean,
            default: true
        },
    },
    data() {
        const defaultConfig = {
            language: 'pt_BR',
            height: this.height,
            resize: this.resize,
            forced_root_block: 'div',
            object_resizing: 'img',
            id: this.editorId,
            menubar: false,
            statusbar: this.statusBar,

            codesample_languages: [
                {text: 'HTML/XML', value: 'markup'},
                {text: 'JavaScript', value: 'javascript'},
                {text: 'CSS', value: 'css'},
                {text: 'PHP', value: 'php'},
                {text: 'Ruby', value: 'ruby'},
                {text: 'Python', value: 'python'},
                {text: 'Java', value: 'java'},
                {text: 'C', value: 'c'},
                {text: 'C#', value: 'csharp'},
                {text: 'C++', value: 'cpp'}
            ],            


            setup: editor => {

                editor.ui.registry.addMenuButton('coloredBox', {
                    icon: 'cell-background-color',
                    tooltip: 'Caixa Colorida',
                    fetch: callback => {
                        const items = [
                            {
                                type: 'menuitem',
                                text: 'Azul Claro',
                                onAction: () => {
                                    const blueBoxHTML = `<div style="width: 100%; padding: 5px; background-color: #abcbff;">
                                        Conteúdo aqui
                                    </div>
                                    `;
                                    editor.insertContent(blueBoxHTML);
                                }
                            },
                            {
                                type: 'menuitem',
                                text: 'Amarelo Claro',
                                onAction: () => {
                                    const yellowBoxHTML = `<div style="width: 100%; padding: 5px; background-color: #fcf7bb;">
                                        Conteúdo aqui
                                    </div>
                                    `;
                                    editor.insertContent(yellowBoxHTML);
                                }
                            },
                            {
                                type: 'menuitem',
                                text: 'Vermelho Claro',
                                onAction: () => {
                                    const redBoxHTML = `<div style="width: 100%; padding: 5px; background-color: #fac0e2;">
                                        Conteúdo aqui
                                    </div>
                                    `;
                                    editor.insertContent(redBoxHTML);
                                }
                            },
                            {
                                type: 'menuitem',
                                text: 'Verde Claro',
                                onAction: () => {
                                    const greenBoxHTML = `<div style="width: 100%; padding: 5px; background-color: #c1f5d3;">
                                        Conteúdo aqui
                                    </div>
                                    `;
                                    editor.insertContent(greenBoxHTML);
                                }
                            },
                            {
                                type: 'menuitem',
                                text: 'Azul escuro',
                                onAction: () => {
                                    const greenBoxHTML = `<div style="width: 100%; padding: 5px; background-color: #020e2b; color: #ffffff;">
                                        Conteúdo aqui
                                    </div>
                                    `;
                                    editor.insertContent(greenBoxHTML);
                                }
                            },
                            {
                                type: 'menuitem',
                                text: 'Verde escuro',
                                onAction: () => {
                                    const greenBoxHTML = `<div style="width: 100%; padding: 5px; background-color: #00151f; color: #ffffff;">
                                        Conteúdo aqui
                                    </div>
                                    `;
                                    editor.insertContent(greenBoxHTML);
                                }
                            },
                                                        

                        ];
                        callback(items);
                    }
                });
     
                editor.ui.registry.addButton('insertVideo', {
                    icon: 'embed', 
                    tooltip: 'Inserir Vídeo',
                    onAction: () => {
                        editor.windowManager.open({
                            title: 'Inserir URL do Vídeo do YouTube',
                            body: {
                                type: 'panel',
                                items: [
                                    {
                                        type: 'input',
                                        name: 'videoUrl',
                                        label: 'URL do Vídeo'
                                    }
                                ]
                            },
                            buttons: [
                                {
                                    type: 'cancel',
                                    text: 'Cancelar'
                                },
                                {
                                    type: 'submit',
                                    text: 'Inserir',
                                    primary: true
                                }
                            ],
                            onSubmit: api => {
                                const data = api.getData();
                                const videoEmbed = `
                                <div style="position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden;">
                                    <iframe style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" src="https://www.youtube.com/embed/${extractVideoId(data.videoUrl)}" frameborder="0" allowfullscreen></iframe>
                                </div>`;
                                editor.insertContent(videoEmbed);
                                api.close();
                            }
                        });
                    }
                });

                editor.ui.registry.addSplitButton('columns', {
                    icon: 'table',
                    tooltip: 'Colunas',
                    onAction: () => {
                        // Ação padrão ao clicar no botão principal (neste caso, insere duas colunas)
                        const columnHTML = `<table style="width: 100%;">
                            <tr>
                                <td style="width: 50%; border: 1px solid #ccc;">Coluna 1</td>
                                <td style="width: 50%; border: 1px solid #ccc;">Coluna 2</td>
                            </tr>
                        </table>`;
                        editor.insertContent(columnHTML);
                    },
                    onItemAction: (api, value) => {
                        let columnHTML = '';
                        if (value === 'two') {
                            columnHTML = `<table style="width: 100%;">
                                <tr>
                                    <td style="width: 50%; padding: 5px;">Coluna 1</td>
                                    <td style="width: 50%; padding: 5px;">Coluna 2</td>
                                </tr>
                            </table>`;

                        } else if (value === 'three') {
                            columnHTML = `<table style="width: 100%;">
                                <tr>
                                    <td style="width: 33%; padding: 5px;">Coluna 1</td>
                                    <td style="width: 33%; padding: 5px;">Coluna 2</td>
                                    <td style="width: 33%; padding: 5px;">Coluna 3</td>
                                </tr>
                            </table>
                            `;
                        } else if (value === 'four') {
                            columnHTML = `<table style="width: 100%;">
                                <tr>
                                    <td style="width: 25%; padding: 5px;">Coluna 1</td>
                                    <td style="width: 25%; padding: 5px;">Coluna 2</td>
                                    <td style="width: 25%; padding: 5px;">Coluna 3</td>
                                    <td style="width: 25%; padding: 5px;">Coluna 4</td>
                                </tr>
                            </table>
                            `;
                        } else if (value === 'two_border') {
                            columnHTML = `<table style="width: 100%;">
                                <tr>
                                    <td style="width: 50%; border: 1px solid #ccc; padding: 5px;">Coluna 1</td>
                                    <td style="width: 50%; border: 1px solid #ccc; padding: 5px;">Coluna 2</td>
                                </tr>
                            </table>
                            `;
                        } else if (value === 'three_border') {
                            columnHTML = `<table style="width: 100%;">
                                <tr>
                                    <td style="width: 33%; border: 1px solid #ccc; padding: 5px;">Coluna 1</td>
                                    <td style="width: 33%; border: 1px solid #ccc; padding: 5px;">Coluna 2</td>
                                    <td style="width: 33%; border: 1px solid #ccc; padding: 5px;">Coluna 3</td>
                                </tr>
                            </table>
                            `;
                        } else if (value === 'four_border') {
                            columnHTML = `<table style="width: 100%;">
                                <tr>
                                    <td style="width: 25%; border: 1px solid #ccc; padding: 5px;">Coluna 1</td>
                                    <td style="width: 25%; border: 1px solid #ccc; padding: 5px;">Coluna 2</td>
                                    <td style="width: 25%; border: 1px solid #ccc; padding: 5px;">Coluna 3</td>
                                    <td style="width: 25%; border: 1px solid #ccc; padding: 5px;">Coluna 4</td>
                                </tr>
                            </table>
                            `;
                        }
                        editor.insertContent(columnHTML);
                    },
                    fetch: (callback) => {
                        var items = [
                            {
                                type: 'choiceitem',
                                text: '2 Colunas',
                                value: 'two'
                            },
                            {
                                type: 'choiceitem',
                                text: '3 Colunas',
                                value: 'three'
                            },
                            {
                                type: 'choiceitem',
                                text: '4 Colunas',
                                value: 'four'
                            },
                            {
                                type: 'choiceitem',
                                text: '2 Colunas borda',
                                value: 'two_border'
                            },
                            {
                                type: 'choiceitem',
                                text: '3 Colunas borda',
                                value: 'three_border'
                            },
                            {
                                type: 'choiceitem',
                                text: '4 Colunas borda',
                                value: 'four_border'
                            }                            
                        ];
                        callback(items);
                    }
                });
            

            },
        };

        const mergedConfig = Object.assign({}, defaultConfig, this.customConfig);

        if (this.customConfig.setup) {
            mergedConfig.setup = editor => {
                defaultConfig.setup(editor);
                this.customConfig.setup(editor);
            };
        }
        
        return {
            message: '',
            apiKey: "40c77dj0ntxwoeie5qutoxzbujrcfctcdpxyiz4yan78ig7z",
            lock: false,
            conf: mergedConfig,
        };

    },
    created() {
        if (this.value) {
            this.message = String(this.value);
        }
        if (this.initialValue) {
            this.setData(this.initialValue);
        }
    },
    mounted() {
        setTimeout(() => {
            this.lock = true;
        }, 300);
    },
    methods: {
        updateValue(event) {
            this.$emit('update:modelValue', event.target.value);
        },
        setData(txt, timeout = 300) {
            setTimeout(() => {
                getTinymce().activeEditor.setContent(txt);
            }, timeout);
        },
        strip(html) {
            let doc = new DOMParser().parseFromString(html, 'text/html');
            return doc.body.textContent || "";
        },
    },
    watch: {
        message(newValue) {
            this.$emit('update:modelValue', newValue);
        },
        value(newValue) {
            if (this.lock === false) {
                this.setData(newValue);
                this.lock = true;
            }
        },
        initialValue(newValue) {
            this.setData(newValue);
        },
    },
}
</script>

<style scoped>
/* Mesmos estilos que o exemplo anterior */
pre[class*="language-"] {
  background-color: #2d2d2d !important;
  color: white !important;
}
code[class*="language-"] {
  color: white !important;
}
</style>