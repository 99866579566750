<template>
    <TemplateDefault :slotKey="slotKey">
        <div v-if="showIcon" class="flex justify-center items-center cursor-pointer" @click="openLive">
            <div v-if="icon && icon.includes('mp4')">
                <video id="transparent-video" class="h-[80px] w-[80px] rounded-full" autoplay loop muted>
                    <source :src="icon" type="video/webm" @error="handleError">
                </video>
            </div>
            <div v-else>
                <img :src="icon" class="h-[80px] w-[80px]" alt="Click" title="Click">
            </div>
        </div>
    </TemplateDefault>
</template>

<script>
import TemplateDefault from '@/resources/templates/TemplateDefault.vue';

export default {
    name: 'Widget',

    components: {
        TemplateDefault
    },

    data() {
        return {
            page: 'home',
            slotKey: 'widget',
            showIcon: false,
            icon: null
        };
    },

    methods: {

        handleError(event) {

            console.log('chamo')

            // Caminho para o vídeo ou imagem padrão
            event.target.src = '/img/teclia.mp4'; 
        },

        async openLive() {

            const query = this.$route.query;
            const session = await this.$functions.saveSession(query);

            if (session && session.attributes) {

                if (session.attributes.widget_open == 'popup') {

                    const currentQuery = this.$route.query;

                    // Transformar o objeto de query em uma string de parâmetros
                    const queryString = new URLSearchParams(currentQuery).toString();

                    // URL base para o popup
                    const url = `/home?${queryString}`;

                    // Dimensões do popup
                    const width = 400;
                    const height = 600;

                    // Abrir o popup com a posição desejada
                    window.open(url, 'tecliaPopup', `width=${width},height=${height}, bottom=100,right=100, top=100%, left=100% toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no`);
                    return;

                } else {
                    this.showIcon = false;
                    await this.$functions.changeIframeClass('home', 'open', 'home', this.$route.query, {}); //class, status, rota, query, params
                    await new Promise(resolve => setTimeout(resolve, 100));
                    this.$router.push({ name: 'home', query: this.$route.query });
                }
            }


        }
    },

    async mounted() {
        const query = this.$route.query;
        const session = await this.$functions.saveSession(query);

        if (session && session.attributes && session.attributes.icon) {

            if (window.name != 'tecliaWindow') {
                this.$router.push({ name: 'home', query: this.$route.query });
                return;
            }

            this.icon = session.attributes.icon;
            this.showIcon = true;
        }
    }
}
</script>
