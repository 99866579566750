<template>
  <div class="py-2">    
    <label for="{{forLabel}}" class="block text-xss text-gray-700"><span v-if="required" class="text-rose-600">*</span> {{label}}</label>    
    <div v-if="sub" class="text-xs">{{ sub }}</div>
    <div class="">
      <input :type="type"  :maxlength="maxlength" :value="value" @input="updateValue" :placeholder="placeholder" :disabled="disabled" :class="classes"  />
      <div v-if="description" class="text-xs">{{description}}</div>
      
      <div v-if="min && max" class="text-xs">(entre {{ min }} e {{ max }})</div>

      <p v-if="error" class="mt-2 text-sm text-rose-600">{{error}}</p>
      <div v-if="info" class="text-sm text-gray-500 pt-1">{{info}}</div>
    </div>
  </div>
</template>

<script>

export default {
    name: 'InputText',
    props: {
      min: {
        type: Number,
      },
      max: {
        type: Number,
      },
      maxlength: {
        type: String,
      },
      type: {
          type: String,
          default: 'text',
      },
      required: {
          type: Boolean,
          default: false,
      },
      name: {
          type: String,
          required: false,
      },
      field_id: {
          type: [String, Number],
          required: false,
      },
      label: {
        type: String,
        required: false,
      }, 
      sub: {
        type: String,
        required: false,
      },     
      description: {
        type: String,
        required: false,
      },  
      value: [String, Object, Boolean, Number],
      placeholder: String,
      error: String,
      info: String,
      disabled: Boolean,
      fieldClass: String,
      fieldsError: {
        type: Object,
        required: false,
      },            
    },

    data() {
        return {
            errorClass: '',
        }
    },

    computed: {
      classes() {
        return [
          'defaultInput',
          this.name ? this.fieldsError[this.field_id] || this.fieldsError?.[this.name] ? 'bg-rose-50' : '': '',
          this.fieldClass,
        ]
        .filter(Boolean)
        .join(' ');
      },
    },
  
    methods: {
        updateValue(event) {
          if(this.field_id){
            this.$emit('update:modelValue', {value: event.target.value, field_id: this.field_id, label: this.label});
           
          } else {
            this.$emit('update:modelValue', event.target.value);
          }
            
        },
        forLabel() {
            return this.label.replace(/\s/g, '').toLowerCase();
        },
    },

    created() {
      if(this.field_id){
        this.$emit('update:modelValue', {value:this.value, field_id: this.field_id, label: this.label});
        
      } else {
        this.$emit('update:modelValue', this.value);
      }
      
    },
}
</script>