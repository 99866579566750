<template>
    <div class="flex items-center">
        <label for="file-input"
            class="flex items-center h-10 w-10 justify-center text-gray-400 hover:text-gray-900 cursor-pointer">
            <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13">
                </path>
            </svg>
        </label>
        <input id="file-input" type="file" multiple accept="text/html, video/*, audio/*, .pdf, application/vnd.ms-excel, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain, image/*"  @change="onFilesSelected" class="hidden" />
    </div>
</template>

<script>
import { useLiveStore } from '@/stores/liveStore';

export default {
    name: 'ChatUploadInput',
    props: {
        ticket_id: {
            type: [String, Number],
            required: true
        },
        files: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            uploadQueue: [], // Fila de upload
            isUploading: false // Indicador de upload em andamento
        };
    },
    methods: {
        onFilesSelected(event) {
            const maxFileSize = 10 * 1024 * 1024; // 10MB
            const maxFiles = 5;
            const selectedFiles = Array.from(event.target.files);

            if (selectedFiles.length > maxFiles) {
                alert(`Você pode enviar no máximo ${maxFiles} arquivos por vez.`);
                return;
            }

            const validFiles = selectedFiles.filter(file => file.size <= maxFileSize);

            if (validFiles.length !== selectedFiles.length) {
                alert('Alguns arquivos foram removidos porque excedem o limite de 10MB.');
            }

            validFiles.forEach(file => {
                this.prepareFile(file);
            });

            event.target.value = ''; // Clear the input after files are selected
        },
        prepareFile(file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                let preview = '';
                if (file.type.startsWith('image/')) {
                    preview = e.target.result; // Use the image data as preview
                } else {
                    preview = this.getFileIcon(file.type); // Use an icon based on the file type
                }
                this.$emit('file-status-update', { name: file.name, type: file.type, status: 'uploading', preview });
                this.uploadQueue.push({ name: file.name, data, preview }); // Adiciona à fila de upload
                this.processUploadQueue(); // Inicia o processamento da fila se não estiver em andamento
            };
            reader.readAsDataURL(file);
        },
        processUploadQueue() {
            if (this.isUploading || this.uploadQueue.length === 0) return;

            this.isUploading = true;
            const file = this.uploadQueue.shift();
            this.sendFile(file.name, file.data, file.preview);
        },
        async sendFile(fileName, data, preview) {
            const liveStore = useLiveStore();
            liveStore.socket.emit('chat:upload:file', { ticket_id: this.ticket_id, fileName, data }, async (response) => {
                if (response.success) {
                    this.$emit('file-status-update', { name: fileName, status: 'uploaded', url: response.filePath, preview });
                } else {
                    this.$emit('file-status-update', { name: fileName, status: 'error', message: response.message, preview });
                }
                this.isUploading = false;
                await this.wait(5000); // Aguarda 1 segundo antes de processar o próximo upload
                this.processUploadQueue(); // Processa o próximo arquivo na fila
            });
        },
        wait(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        getFileIcon(fileType) {
            switch (true) {
                case fileType.includes('pdf'):
                    return '/path/to/pdf-icon.png';
                case fileType.includes('word'):
                case fileType.includes('document'):
                    return '/path/to/word-icon.png';
                case fileType.includes('spreadsheet'):
                case fileType.includes('excel'):
                    return '/path/to/excel-icon.png';
                default:
                    return '/path/to/default-file-icon.png';
            }
        }
    }
}
</script>
