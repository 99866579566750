<template>
  <div v-if="files.length > 0" class="flex flex-wrap gap-4">    
      <UploadFile :file="file" :key="`${ file.name }-${ file.status }`" :onDelete="onDelete" v-for="file in files" />
  </div>
</template>

<script>
import UploadFile from './UploadFile.vue';

export default {
  name: 'UploadFileList',

  props: {
    files: {
      type: Array,
      required: true,
    },

    onDelete: {
      type: Function,
      required: true,
    },
  },

  components: {
    UploadFile,
  },
}
</script>
