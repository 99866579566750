export default class FormUtils {

    static showErrors(errorData) {

        let showErrors = {};
        // verifica se errors é um array
        if (Array.isArray(errorData)) {
            errorData.forEach((error) => {
                showErrors[error.field] = error.message
            });
        } else {
            showErrors.message = errorData;
        }
        return showErrors;
    }

    static errorMessage(message) {
        if(message){

            if(message === 'Invalid token'){

                // abre a rota logout
                window.location.href = '/auth/logout';

                return;



            }

            // TODO: Create a new component for this to show message errors

        }
        
    }


}