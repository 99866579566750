export default class DepartamentsFunctions {



    static organizeDeptos(deptosArray){
        let deptosOrganizados = [];
        for(let i = 0; i < deptosArray.length; i++){
          let nivel = 0;

          
          if(deptosArray[i].recursive_id == null){
            const id_depto = deptosArray[i].id
            deptosArray[i].nivel = nivel;
            deptosOrganizados.push(deptosArray[i]);

            let filhos = this.encontraFilhos(deptosArray, id_depto, nivel);          

            if(filhos.length > 0){
              deptosOrganizados = deptosOrganizados.concat(filhos);
            } 
          }       
        }
        return deptosOrganizados;
      }

      static encontraFilhos(deptosArray, idDeptoPai, nivel){      
        let filhos = [];
        for(let i = 0; i < deptosArray.length; i++){

          if(deptosArray[i].recursive_id == idDeptoPai){
            deptosArray[i].nivel = nivel + 1;
            filhos.push(deptosArray[i]);

            let filhosFilhos = this.encontraFilhos(deptosArray, deptosArray[i].id, nivel + 1);
            if(filhosFilhos.length > 0){
              filhos = filhos.concat(filhosFilhos);
            }
          }
        }
        return filhos;
      }
}
