<template>
  <div class="pt-2">
    <label for="{{forLabel}}" class="block text-sm font-medium text-gray-700"><span v-if="required" class="text-rose-600">*</span> {{label}}</label>
    <div v-if="description" class="text-gray-500 text-xs">
      {{description}}
    </div>    
    <Combobox v-model="selected" >
      <div class=" relative mt-1">
        
        <div class="relative">
          <ComboboxButton v-if="selected != ''" @click="unSelected()" class="absolute right-8 top-3">
            <XMarkIcon class="h-4 cursor-pointer"/>
          </ComboboxButton>
          <ComboboxInput  class="defaultInput" :class="name ? fieldsError[name] ? 'bg-rose-50' : '': ''" :displayValue="(item) => item.name"  @change="searchTo" />
          <ComboboxButton class="absolute inset-y-0 right-0 flex items-center pr-2" >
            <ArrowsUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          </ComboboxButton>
        </div>
        <TransitionRoot leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0" @after-leave="query = ''">        
          <ComboboxOptions class=" z-10 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <div v-if="filteredDataRows.length === 0 && query !== ''" class="relative cursor-default select-none py-2 px-4 text-gray-700">
              Not found
            </div>
            <ComboboxOption v-for="item in filteredDataRows" as="template" :key="item.id" :value="item" v-slot="{ selected, active }">
              <li @click="update(item)" class="relative cursor-default select-none py-2 pl-10 pr-4" :class="{ 'bg-indigo-600 text-white': active, 'text-gray-900': !active,}">
                <span class="truncate flex" :class="{ 'font-medium': selected, 'font-normal': !selected }">
                  <div v-if="item.nivel" class="pr-2">
                  {{nivel(item.nivel)}}                    
                  </div>
                  {{ item.name }}
                </span>
                <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-3" :class="{ 'text-white': active, 'text-teal-600': !active }">
                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                </span>
              </li>
            </ComboboxOption>
          </ComboboxOptions>
        </TransitionRoot>
      </div>
    </Combobox>
  </div>
</template>

<script>

import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot,
} from '@headlessui/vue'

import { CheckIcon, ArrowsUpDownIcon, XMarkIcon } from '@heroicons/vue/24/solid'

export default {

  name: 'DropDownSearch',
  props: {
    name: {
        type: String,
        required: false,
    },
    fieldsError: {
      type: Object,
      required: false,
    },          
    field_id: {
        type: [String, Number],
        required: false,
    },
    field_type: String,
    label: {
      type: String,
      required: false,
    },      
    required: {
      type: Boolean,
      required: false,
    },
    description: String,    
    dataRows: Array,
    deptoId: String,
    value: [String, Object, Boolean, Number],
    valueType: [String],
  },
  data() {
    return {
      query: '',
      item: {},
      selected: '',
      selectedBackup: '',
    }
  },
  components: {
    Combobox,
    ComboboxInput,
    ComboboxButton,
    ComboboxOptions,
    ComboboxOption,
    TransitionRoot,
    CheckIcon,
    ArrowsUpDownIcon,
    XMarkIcon,
  },
  methods: {
    searchTo($event) {
      this.query = $event.target.value
    },
    unSelected() {
      this.selected = '',
      this.$emit('update:modelValue', null);
    },

    update(item) {
      this.item = item

      if (this.valueType) {
        if(this.field_id){
            this.$emit('update:modelValue', {value: this.item[this.valueType], field_id: this.field_id, label: this.label, field_type: this.field_type, id: this.item.id});
          } else {
            this.$emit('update:modelValue', this.item[this.valueType]);
          }        
      } else {
        this.$emit('update:modelValue', this.item.id);
      }
    },

    nivel(nivel){
      let seta = '|';
      if(nivel && nivel > 0){                
          for(let i = 0; i <= nivel; i++){
              seta = seta + 'ㅤ';
          }
          seta = seta+'›';
      }
      else {
          seta = '•';
      }
      return seta            
    },

    search(item) {
      return item.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1
    },

  },

  computed: {
    filteredDataRows() {

      // Impede que sejam mostrados dados (apenas não retorna nada)
      if(!this.dataRows){
        return ''
      }

      let showName = true;
      let rows = [];
      let item = {};

      // Se passei um Depto ID é pq quero excluir departamentos
        if((this.value && this.deptoId) || (this.selectedBackup && this.deptoId)){      
          for(let i = 0; i < this.dataRows.length; i++){
            item = this.dataRows[i];

            if(item.nivel == 0){
              if(item.id == this.value || item.id == this.selectedBackup.id){
                showName = false;
              } else {
                showName = true;
                if(this.query){
                  if(this.search(item)){ rows.push(item); }
                }
                else {
                  rows.push(item);
                }
              }           
            }
            else {
              if(showName){
                if(item.id == this.value || item.id == this.selectedBackup.id){
                  showName = false;
                    if(this.query){
                      if(this.search(item)){ rows.push(item); }
                    }
                    else { rows.push(item);}
                }
                else {
                    if(this.query){
                      if(this.search(item)){ rows.push(item);}
                    }
                    else { rows.push(item);}
                }
              }
            }
          }
          return rows;
        }
        
        // TODO: Resolver problema do departamento principal
        // (permite selecionar o departamento principal e outros sub departamentos
        return this.dataRows.filter(item => {
          if(this.search(item)){ return item.name}          
        });

    },
  },

  created() {
    if(this.value){
      this.selected = {
        name: this.value,
        nivel: 0,
      }
    }

    if(this.dataRows){
      for(let i = 0; i < this.dataRows.length; i++){
        if(this.dataRows[i].id == this.value){
          this.selected = this.dataRows[i];
          this.selectedBackup = this.dataRows[i];
          this.item = this.dataRows[i];
        }
      }    
    }
    else {
      this.selected = {
        id: 1,
        name: 'Waiting for data',
        nivel: 0,
      };
      
    }
  },
}

</script>

